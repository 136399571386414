<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card class="d-flex flex-column" style="height: 100%">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Delete Manual Time</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="title-label">Date</label>
              <v-text-field
                v-model="startDateStr"
                readonly
                solo
                flat
                outlined
                dense
                autocomplete="off"
                append-icon="mdi-calendar-month-outline"
                hide-details=""
                style="width: 100%;"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="title-label">Shift</label>
              <v-select
                v-model="shift"
                :items="responses"
                label="Shift"
                item-text="shift.title"
                item-value="_id"
                style="width: 100%;"
                outlined
                dense
                solo
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-row"
              style="align-items: baseline"
            >
              <v-row>
                <v-col>
                  <label class="title-label">Start time:</label>
                  <vue-timepicker
                    format="hh:mm a"
                    v-model="startTime"
                    :minute-interval="5"
                    disabled
                  />
                </v-col>
                <v-col>
                  <label class="title-label">End time:</label>
                  <vue-timepicker
                    format="hh:mm a"
                    v-model="endTime"
                    :minute-interval="5"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="title-label">Memo</label>
              <v-textarea
                v-model="memo"
                rows="5"
                counter="140"
                label="Short description of the work done"
                solo
                outlined
                style="width: 100%;"
                readonly
              />
            </v-col>
          </v-row>
        </v-container>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="deleteTime"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: {
    VueTimepicker
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    timeslot: Object,
    responses: {
      type: Array
    },
    onCloseDialog: {
      type: Function
    },
    onDeletedTime: {
      type: Function
    }
  },
  data() {
    return {
      startDate: null,
      date_picker: false,
      startTime: null,
      endTime: null,
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      maxDate: "2021-01-01",
      startDateStr: "Dec 31, 2021",
      pickerDate: null,
      today: null,
      memo: "",
      shift: null
    };
  },
  methods: {
    ...mapActions("timeslot", {
      deleteManualTime: "deleteManualTime"
    }),
    onClose() {
      this.memo = "";
      this.startTime = null;
      this.endTime = null;
      this.onCloseDialog();
    },
    deleteTime() {
      this.error = null;
      this.loading = true;
      this.deleteManualTime({
        _id: this.timeslot._id
      })
        .then(res => {
          this.loading = false;
          this.memo = "";
          this.onDeletedTime(res);
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.snackbar = true;
          this.snackMessage = error.response.data.message;
        });
    },
    convertTZ(date, tzString) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: tzString ? tzString : "America/New_York"
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone"
    })
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.startDateStr = dateFormat(this.initDate, "mmm d, yyyy");
        this.memo = this.timeslot.memo;
        var st = this.convertTZ(this.timeslot.start, this.timezone);
        this.startTime = {
          hh: dateFormat(st, "hh"),
          mm: dateFormat(st, "MM"),
          a: dateFormat(st, "tt")
        };
        var et = this.convertTZ(this.timeslot.end, this.timezone);
        this.endTime = {
          hh: dateFormat(et, "hh"),
          mm: dateFormat(et, "MM"),
          a: dateFormat(et, "tt")
        };
        this.shift = this.timeslot.response_id;
      }
    }
  },
  mounted() {
    this.startDateStr = dateFormat(this.initDate, "mmm d, yyyy");
    this.memo = this.timeslot.memo;
    var st = this.convertTZ(this.timeslot.start, this.timezone);
    this.startTime = {
      hh: dateFormat(st, "hh"),
      mm: dateFormat(st, "MM"),
      a: dateFormat(st, "tt")
    };
    var et = this.convertTZ(this.timeslot.end, this.timezone);
    this.endTime = {
      hh: dateFormat(et, "hh"),
      mm: dateFormat(et, "MM"),
      a: dateFormat(et, "tt")
    };
    this.shift = this.timeslot.response_id;
  }
};
</script>
<style scoped>
div :deep(.v-dialog) {
  width: 70%;
  max-width: 900px;
}
.title-label {
  font-weight: bold;
  margin-bottom: 10px;
}

div :deep(.v-dialog) {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}

.date-picker {
  border-radius: 4px 0 0 4px;
}
.time-picker {
  border-radius: 0 4px 4px 0;
}
.date-picker :deep(.v-picker__title) {
  max-height: 87px;
}
.time-picker {
  width: 100%;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
